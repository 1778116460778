import React from 'react';
import t from '@jetshop/intl';
import { Currency } from '@jetshop/ui/Price/Currency';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { getSavedAmount } from '../../helpers';
import Styled from './Style';

const ProductDiscountBadge = ({ product, variant, size, ...props }) => {
  const price = useDynamicPrice(product, variant);
  const { hasDiscount } = usePrice(price);
  const savedAmount = hasDiscount ? getSavedAmount(price) : null;

  if (!hasDiscount) return null;

  return (
    <Styled.Badge size={size} {...props}>
      <Styled.Text>{t('Save')} </Styled.Text>
      <Currency value={savedAmount} />
    </Styled.Badge>
  );
};

export default ProductDiscountBadge;
