import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const StyledGrid = styled.div`
  --gap-width: ${({ gap }) => (gap && theme.gap[gap]) || theme.gap.medium};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-top: calc(${({ gap }) => (gap && theme.gap[gap]) || theme.gap.medium}) *
    -1;
  &:empty {
    display: none;
  }
`;

export const StyledGridCell = styled.div`
  width: ${props => props.xwidth || '100%'};
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ align }) => align || 'stretch'};
  padding-left: var(--gap-width);
  padding-top: var(--gap-width);
  ${theme.above.xs} {
    width: ${props => props.xs || props.xwidth || '100%'};
  }
  ${theme.above.sm} {
    width: ${props => props.sm || props.xs || props.xwidth || '100%'};
  }
  ${theme.above.md} {
    width: ${props =>
      props.md || props.sm || props.xs || props.xwidth || '100%'};
  }
  ${theme.above.lg} {
    width: ${props =>
      props.lg || props.md || props.sm || props.xs || props.xwidth || '100%'};
  }

  ${theme.above.xl} {
    width: ${props =>
      props.xl ||
      props.lg ||
      props.md ||
      props.sm ||
      props.xs ||
      props.xwidth ||
      '100%'};
  }
`;

export const GridCell = ({
  children,
  width, // string | number
  xs,
  sm,
  md,
  lg,
  xl,
  align = 'start'
}) => {
  const breakpoints = { xs, sm, md, lg, xl };
  const assignedBreakpoints = Object.keys(breakpoints).reduce(
    (all, key) => ({
      ...all,
      ...(breakpoints[key]
        ? {
            [key]: isNaN(breakpoints[key])
              ? breakpoints[key]
              : `${Number(breakpoints[key]) * 100}%`
          }
        : {})
    }),
    {}
  );
  return (
    <StyledGridCell align={align} xwidth={width} {...assignedBreakpoints}>
      {children}
    </StyledGridCell>
  );
};

export const Grid = ({
  children, // ReactNode
  gap = 'medium' // 'none' | 'small' | 'medium' | 'large'
}) => {
  return <StyledGrid gap={gap}>{children}</StyledGrid>;
};
