import React, { useContext } from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from '@apollo/react-hooks';
import footerQuery from '../../../gql/queries/FooterQuery.gql';
import FooterContent from './FooterContent/FooterContent';
import TrustpilotBlock from './TrustpilotBlock/TrustpilotBlock';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Styled from '../../../pages/ProductPage/ProductInformation/Style';

const getFooterCategoryId = () => {
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;
  switch (envChannelId) {
    case '1':
      return 153;
    case '5':
      return 378;
    case '9':
      return 376;
    case '13':
      return 380;
    case '17':
      return 382;
    case '18':
      return 384;
    default:
      return 153;
  }
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  var footerCategoryIdTmp;
  if (selectedChannel.id === 3) {
    //Evobike.fi separate footer for Trustpilot
    footerCategoryIdTmp = 476;
  } else if (selectedChannel.id === 7) {
    //Rull.fi separate footer for Trustpilot
    footerCategoryIdTmp = 475;
  } else if (selectedChannel.id === 11) {
    //Blimo.fi separate footer for Trustpilot
    footerCategoryIdTmp = 477;
  } else if (selectedChannel.id === 15) {
    //Sportig.fi separate footer for Trustpilot
    footerCategoryIdTmp = 478;
  } else {
    footerCategoryIdTmp = getFooterCategoryId();
  }
  const footerCategoryId = footerCategoryIdTmp;
  const { data } = useQuery(footerQuery, {
    variables: { footerCategoryId: footerCategoryId }
  });

  const components = {
    BADGE: 'badge',
    LINKGROUPONEITEM: 'linkGroupOneItem',
    LINKGROUPTWOITEM: 'linkGroupTwoItem',
    PAYMENTICON: 'paymentIcon',
    TRUSTPILOTBLOCK: TrustpilotBlock,
    SITEFOOTER: FooterContent
  };

  var trustpilotBusinessIdArr = [];
  trustpilotBusinessIdArr[1] = '591dbf740000ff0005a2b63b'; // Evobike.se
  trustpilotBusinessIdArr[2] = '5daefe37aa319a000155a29b'; // Evobike.no
  trustpilotBusinessIdArr[3] = '5c812492871dac0001ea131a'; // Evobike.fi
  trustpilotBusinessIdArr[4] = '5979c84c0000ff0005a789a9'; // Evobike.dk
  trustpilotBusinessIdArr[5] = '514b03b300006400052476fc'; // Rull.se
  trustpilotBusinessIdArr[6] = '58d128960000ff00059eea18'; // Ruller.no
  trustpilotBusinessIdArr[7] = '5bdad151486407000195d50b'; // Rull.fi
  trustpilotBusinessIdArr[8] = '589b88520000ff00059c56cb'; // Rull.dk
  trustpilotBusinessIdArr[9] = '59d339aa0000ff0005aca8df'; // Blimo.se
  trustpilotBusinessIdArr[10] = '5c0e2f0ca3a083000102e902'; // Blimo.no
  trustpilotBusinessIdArr[11] = '5bdad189dc66fa000134873c'; // Blimo.fi
  trustpilotBusinessIdArr[12] = '5c81244cbbc0f20001f1468a'; // Blimo.dk
  trustpilotBusinessIdArr[13] = '57babf610000ff000593c1bb'; // Sportig.se
  trustpilotBusinessIdArr[14] = '5bdacb43dc66fa00013486d2'; // Sportig.no
  trustpilotBusinessIdArr[15] = '5bdad1c3dc66fa0001348740'; // Sportig.fi
  trustpilotBusinessIdArr[16] = '5c7af553422f860001a15503'; // Sportig.dk
  trustpilotBusinessIdArr[17] = '1'; // Elmoped.se
  trustpilotBusinessIdArr[18] = '1'; // Andningslarm.se

  const trustpilotBusinessId = trustpilotBusinessIdArr[selectedChannel.id];

  return (
    <>
      <ContentRenderer
        items={data?.category?.data?.items ?? []}
        components={components}
      />

      <Styled.TrustpilotTest>
        <div
          class="trustpilot-widget"
          data-locale={selectedChannel?.language?.culture}
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id={trustpilotBusinessId}
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
        ></div>
      </Styled.TrustpilotTest>
    </>
  );
};

export default Footer;
