import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import { ReactComponent as ChevronDown } from '../../../assets/svg/ChevronDown.svg';
import { toRem } from '../../../helpers';

const Content = styled('article')`
  line-height: 1.5rem;
  padding-top: 1.5rem;
  position: relative;

  a {
    color: inherit;
  }
  p {
    margin: 0.5rem 0;
  }
  strong {
    font-weight: ${theme.fontWeights.medium};
  }
  ul {
    list-style: initial;
    padding: 1rem 0 1rem 1.5rem;
  }

  overflow: hidden;
  height: ${props => (props.open ? 'auto' : '150px')};
  min-height: 50px;
  &::before {
    display: ${props => (props.open ? 'none' : 'block')};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%
    );
  }
`;

const SectionHeading = styled('h2')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: ${theme.fontWeights.semibold};
  cursor: pointer;
`;

const ProductSection = styled('div')`
  border-color: ${theme.colorScheme.superlightgrey};
  border-style: solid;
  border-width: 1px 1px 0 1px;
  padding: 0.75rem 1.25rem;
  &:last-child {
    border-bottom-width: 1px;
  }
`;

const Wrapper = styled('div')`
  width: 100%;

  ${theme.above.md} {
    width: calc(50% - 16px);
    padding-left: 113px;
    padding-right: 0;
  }

  ${theme.above.lg} {
    width: calc(60% - 24px);
  }
`;

const ChevronIcon = styled(ChevronDown)`
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.25s;
`;

const SpecificationsWrapper = styled('div')`
  padding: 1.5rem 0;
  font-size: 0.875rem;

  tr {
    border-bottom: 1px solid ${theme.colorScheme.superlightgrey};

    &:last-child {
      border-bottom: 0;
    }
  }

  th {
    padding-left: 0;
  }
`;
const TrustpilotTest = styled('div')`
  display: none;
`;

const DocumentLink = styled('a')`
  display: block;
  padding: 0.25rem 0;
  color: inherit;
`;

const ButtonContainer = styled('div')`
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  display: grid;
  ${theme.below.md} {
    margin-top: 1.25rem;
  }
`;

const Button = styled('button')`
  border: none;
  background: transparent;
  text-decoration: underline;
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.semibold};
`;

const style = {
  Content,
  ChevronIcon,
  DocumentLink,
  ProductSection,
  SectionHeading,
  Wrapper,
  SpecificationsWrapper,
  ButtonContainer,
  Button,
  TrustpilotTest
};

export default style;
