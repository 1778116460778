import React, { useState, useRef, useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import {
  DropdownMenu as DropdownMenuWrapper,
  DropdownMenuItem
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as ChevronDown } from '../../../assets/svg/ChevronDown.svg';
import Styled from './Style';

const StyledDropdownMenuWrapper = styled.div`
  .DropdownMenu-items {
    display: none;
  }
`;
const StyledDropdownMenu = styled(DropdownMenuWrapper)`
  &.invalid {
    color: rgba(0, 0, 0, 0.5);
  }
`;
const StyledDropdownMenuButton = styled.button``;
const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  &.invalid {
    opacity: 0.3;
  }
`;
const StyledDropdownMenuItemsWrapper = styled.div`
  position: fixed;
  z-index: 2;
  padding: 0 3px;
`;
const StyledDropdownMenuItems = styled.ul``;
const StyledOptionContent = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: flex-start;
  align-items: center;
`;

const DropdownMenu = ({
  id,
  label,
  name,
  value,
  placeholder,
  options = [],
  isValid = true,
  disabled,
  children,
  onSelect,
  inline,
  size = 'large' // small | large
}) => {
  const [selectedOption, setSelectedOption] = useState(
    (value && options.find(opt => String(opt.value) === String(value))) || {}
  );

  let isMouseDown = false;

  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const menuListEl = useRef();
  const wrapperEl = useRef();
  const buttonEl = useRef();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(
    () => () => {
      if (menuListEl.current) {
        menuListEl.current.remove();
      }
      closeDropdown();
      mouseup();
    },
    []
  );

  const setSelectedValue = value =>
    setSelectedOption(
      options.find(opt => String(opt.value) === String(value)) || {}
    );
  function toggleDropDown(e) {
    e.stopPropagation();

    if (!isOpen) {
      openDropdown();
    } else {
      closeDropdown();
    }
  }
  // Prevent mousedown events for clicks inside list
  function mousedown() {
    isMouseDown = true;
    window.document.body.addEventListener('touchend', mouseup);
    window.document.body.addEventListener('mouseup', mouseup);
  }
  function mouseup() {
    isMouseDown = false;
    window.document.body.removeEventListener('touchend', mouseup);
    window.document.body.removeEventListener('mouseup', mouseup);
  }
  function openDropdown() {
    if (!isOpen) {
      setIsOpen(true);
      positionDropDown();

      window.document.body.addEventListener('touchstart', conditionalClose);
      window.document.body.addEventListener('mousedown', conditionalClose);

      window.addEventListener('resize', closeDropdown);
      window.addEventListener('scroll', closeDropdown);
    }
  }
  function closeDropdown(selected) {
    window.document.body.removeEventListener('touchstart', conditionalClose);
    window.document.body.removeEventListener('mousedown', conditionalClose);

    if (menuListEl.current) {
      menuListEl.current.removeEventListener('touchstart', mousedown);
      menuListEl.current.removeEventListener('mousedown', mousedown);
    }
    if (buttonEl.current) {
      buttonEl.current.removeEventListener('touchstart', mousedown);
      buttonEl.current.removeEventListener('mousedown', mousedown);
    }

    window.removeEventListener('resize', closeDropdown);
    window.removeEventListener('scroll', closeDropdown);

    if (menuListEl.current) {
      wrapperEl.current.appendChild(menuListEl.current);
    }
    setIsOpen(false);
  }
  function conditionalClose() {
    if (!isMouseDown) {
      closeDropdown();
    }
  }
  function positionDropDown() {
    const {
      top,
      left,
      height,
      width
    } = wrapperEl.current.getBoundingClientRect();
    setMenuPosition({
      top: `${top + height}px`,
      left: `${left}px`,
      minWidth: `${width}px`
    });
    window.document.body.appendChild(menuListEl.current);
    menuListEl.current.addEventListener('touchstart', mousedown);
    menuListEl.current.addEventListener('mousedown', mousedown);
    buttonEl.current.addEventListener('touchstart', mousedown);
    buttonEl.current.addEventListener('mousedown', mousedown);
  }
  return (
    <StyledDropdownMenuWrapper ref={wrapperEl}>
      <StyledDropdownMenu
        data-flight-dropdown-open={isOpen ? 'true' : 'false'}
        id={id}
        className={cx(
          Styled.dropdownStyles,
          !isValid && 'invalid',
          inline && 'inline',
          `size-${size}`
        )}
      >
        {label ? <label htmlFor={id}>{label}</label> : null}
        {children /* place possible messages above dropdownmenu*/}
        <StyledDropdownMenuButton
          data-flight-dropdown-button
          id={`${id}-menubutton`}
          aria-controls={`${id}-menuitems`}
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={toggleDropDown}
          ref={buttonEl}
        >
          <StyledOptionContent>
            {selectedOption?.icon}
            {selectedOption?.text || placeholder}
          </StyledOptionContent>
          <ChevronDown className="caretIcon" />
        </StyledDropdownMenuButton>
        <StyledDropdownMenuItemsWrapper
          className={cx('DropdownMenu-items')}
          aria-labelledby={`${id}-menubutton`}
          id={`${id}-menuitems`}
          ref={menuListEl}
          style={menuPosition}
          tabIndex="-1"
        >
          <StyledDropdownMenuItems
            className={Styled.dropdownItemsStyles}
            data-flight-dropdown-items
          >
            {options.map(
              ({ text, value, icon, disabled, invalid } = {}, index) => (
                <StyledDropdownMenuItem
                  data-testid={value + (name || id || index)}
                  key={value + (name || id || index)}
                  disabled={disabled}
                  className={(disabled || invalid) && 'invalid'}
                  onSelect={({ setIsOpen: triggerIsOpen }) => {
                    if (typeof value === 'undefined') {
                      setSelectedValue(value);
                    }
                    closeDropdown(true);
                    triggerIsOpen(false);

                    if (typeof onSelect === 'function') {
                      onSelect(value);
                    }
                  }}
                >
                  <StyledOptionContent>
                    {icon}
                    {text}
                  </StyledOptionContent>
                  {String(selectedOption.value) === String(value) && (
                    <Styled.CheckIcon />
                  )}
                </StyledDropdownMenuItem>
              )
            )}
          </StyledDropdownMenuItems>
        </StyledDropdownMenuItemsWrapper>
      </StyledDropdownMenu>
    </StyledDropdownMenuWrapper>
  );
};
export default DropdownMenu;
