import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { toRem } from '../../helpers';

const Badge = styled('div')`
  background-color: ${theme.colorScheme.red};
  padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(10)};
  color: ${theme.colorScheme.white};
  font-size: ${props => (props.size === 'sm' ? toRem(10) : toRem(12))};
  line-height: ${props => (props.size === 'sm' ? toRem(12) : toRem(14))};
  font-weight: ${theme.fontWeights.medium};
  border-bottom-right-radius: ${toRem(11)};
`;

const Text = styled('span')`
  text-transform: uppercase;
`;

const style = { Badge, Text };

export default style;
