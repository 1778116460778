import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from './Theme';

const channelConfig = {
  'Rull.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=4f472833-4047-4eb9-ac01-21c3c19cf488"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'General Swedish Support',
                enabled: ['General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=YxDTQY"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="66df4897-fd59-52c1-8ed2-26a044fa3381"
      ></script>
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.dk/"
        hreflang="da-DK"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.fi/"
        hreflang="fi-FI"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.ruller.no/"
        hreflang="nb-NO"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.se/"
        hreflang="sv-SE"
        data-group="Rull"
      />
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Rull.dk': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=4f472833-4047-4eb9-ac01-21c3c19cf488"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Danish support',
                enabled: ['Danish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=YxDTQY"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="4122a133-883e-516e-96dc-78bab084aff6"
      ></script>
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.dk/"
        hreflang="da-DK"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.fi/"
        hreflang="fi-FI"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.ruller.no/"
        hreflang="nb-NO"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.se/"
        hreflang="sv-SE"
        data-group="Rull"
      />
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Ruller.no': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=4f472833-4047-4eb9-ac01-21c3c19cf488"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Norwegian support',
                enabled: ['Norwegian support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=YxDTQY"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="3e2beecf-a43c-510b-a6e3-9b7b80586004"
      ></script>
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.dk/"
        hreflang="da-DK"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.fi/"
        hreflang="fi-FI"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.ruller.no/"
        hreflang="nb-NO"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.se/"
        hreflang="sv-SE"
        data-group="Rull"
      />
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Rull.fi': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=4f472833-4047-4eb9-ac01-21c3c19cf488"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Finnish support',
                enabled: ['Finnish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=YxDTQY"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="b65b7f0d-dbdb-58b2-90c2-1f005cb68363"
      ></script>
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.dk/"
        hreflang="da-DK"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.fi/"
        hreflang="fi-FI"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.ruller.no/"
        hreflang="nb-NO"
        data-group="Rull"
      />
      <link
        data-rh="true"
        rel="alternate"
        href="https://www.rull.se/"
        hreflang="sv-SE"
        data-group="Rull"
      />
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Evobike.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=d334bd75-8441-44ff-9b84-387ad3150dbc"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Evobike Support',
                enabled: ['Evobike Support','General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=VUJdrZ"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="e1931960-ddca-5799-993f-6820989fc0df"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init',
          {
            websiteUuid: "a2a22c11-bc5f-4ce7-ac2a-0b4be1e060cd",
          },
        ]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Evobike.dk': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=d334bd75-8441-44ff-9b84-387ad3150dbc"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Danish support',
                enabled: ['Danish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=VUJdrZ"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="56d02db5-430d-5c16-9834-9072bd857330"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Evobike.no': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=d334bd75-8441-44ff-9b84-387ad3150dbc"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Norwegian support',
                enabled: ['Norwegian support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=VUJdrZ"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="c9ccf316-6ff4-552f-bffb-107b5311ce7d"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Evobike.fi': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=d334bd75-8441-44ff-9b84-387ad3150dbc"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Finnish support',
                enabled: ['Finnish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=VUJdrZ"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="86629be6-9528-56f3-8a97-c173291a31cd"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Sportig.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=28f1bed5-94a6-40cf-99f0-02110ea3b562"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'General Swedish Support',
                enabled: ['General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QZsB5K"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="129a52b3-981e-5f2e-9b02-a2c84e5539a6"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Sportig.dk': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=28f1bed5-94a6-40cf-99f0-02110ea3b562"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Danish support',
                enabled: ['Danish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QZsB5K"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="e4e74ee2-7cc5-51f1-b503-bea33e073712"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Sportig.no': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=28f1bed5-94a6-40cf-99f0-02110ea3b562"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Norwegian support',
                enabled: ['Norwegian support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QZsB5K"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="37dc42d7-e3ee-5a53-bbc1-a7cd6820928d"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Sportig.fi': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=28f1bed5-94a6-40cf-99f0-02110ea3b562"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Finnish support',
                enabled: ['Finnish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QZsB5K"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="ccd68216-fa38-5f6b-b6e3-1e1128cccaa2"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Blimo.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=0671955b-d801-426f-8609-863947447f55"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Blimo Support',
                enabled: ['Blimo Support','General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=SNdHxf"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="730200a2-a927-56a0-8b5c-dd05da5d56b5"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Blimo.dk': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=0671955b-d801-426f-8609-863947447f55"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Danish support',
                enabled: ['Danish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=SNdHxf"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="7e197d06-b931-52c3-8a4c-a60cb05d555e"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Blimo.no': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=0671955b-d801-426f-8609-863947447f55"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Norwegian support',
                enabled: ['Norwegian support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=SNdHxf"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="7da83669-072d-551c-9b90-04da8b7903a5"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Blimo.fi': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=0671955b-d801-426f-8609-863947447f55"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'Finnish support',
                enabled: ['Finnish support']
              }
            }
          }
        };
        `}
      </script>
      <script
        type="text/javascript"
        src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=SNdHxf"
      />
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="19becf05-2c2c-52d2-a3af-0b22f46c27d4"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Andningslarm.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=9afc9ae3-eed7-44c5-8145-faff89db036e"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'General Swedish Support',
                enabled: ['General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="fcb2835b-2c00-5a92-8eac-7b7a20570c7c"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  ),
  'Elmoped.se': (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=9afc9ae3-eed7-44c5-8145-faff89db036e"
      ></script>
      <script type="text/javascript">
        {`
        window.zESettings = {
          webWidget: {
            chat: {
              departments: {
                select: 'General Swedish Support',
                enabled: ['General Swedish Support']
              }
            }
          }
        };
        `}
      </script>
      <script
        async
        data-environment="production"
        src="https://osm.klarnaservices.com/lib.js"
        data-client-id="58f71d70-b35d-5124-af10-1c574a9e4440"
      ></script>
      <script async src="https://helloretailcdn.com/helloretail.js"></script>
      <script>
        {`
        hrq = window.hrq || [];
        hrq.push(['init', {}]);
        hrq.push(function(sdk){
            try {
                var cartId = getCookie("JetShop_CartID");
                if (cartId) {
                    fetch("/Services/Rest/v2/json/shoppingcart/" + cartId)
                        .then((response) => response.json())
                        .then((data) => {
                            var productNumbers = [];
                            var cart = data[0];
                            for (i = 0; i < cart.ProductsCart.length; i++) {
                                productNumbers.push(cart.ProductsCart[i].Articlenumber);
                            }
                            sdk.setCart({
                                total: cart.TotalProductSum,
                                url: document.location.protocol + "//" + document.location.hostname + "/externalcheckout.aspx?cartid=" + cart.CartId,
                                productNumbers: productNumbers
                            });
                        });
                }
            } catch(e) {
                console.log(e);
            }
        });
        `}
      </script>
    </>
  )
};

export default function ChannelHelmet() {
  const { selectedChannel } = useContext(ChannelContext);
  const favIcon = theme.favIcon;
  const iconType = theme.favIcon.match(/[^.]*$/);
  return (
    <Helmet>
      {favIcon && iconType && (
        <link
          rel="shortcut icon"
          type={iconType === 'ico' ? 'image/x-icon' : `image/${iconType}`}
          href={favIcon}
        />
      )}
      {channelConfig[selectedChannel.name]}
    </Helmet>
  );
}
